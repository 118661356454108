<template>
	<ZyroCard class="card">
		<div class="card__content">
			<h2 class="z-h5 card__title">
				{{ $t('siteSettings.domain.nameserversSettingsStep.title') }}
			</h2>
			<p class="z-body card__subtitle">
				{{ $t('siteSettings.domain.nameserversSettingsStep.subtitle') }}
				<span v-if="currentNameservers.length">
					<br> {{ $t('siteSettings.domain.nameserversSettingsStep.lookLike') }}
				</span>
			</p>
			<InfoBanner
				v-if="currentNameservers.length"
				class="card__banner"
				data-qa="sitesettings-domain-card-nameserversetup"
				:style="{ '--background-color': 'var(--grey-100)' }"
				icon-name="info-circle"
			>
				<template #title>
					<p class="z-body z-body--strong z-body--spaced">
						{{ $t('siteSettings.domain.hasDomain.currentNsSetup') }}
					</p>
				</template>
				<ul class="card__list">
					<li
						v-for="(record, index) in currentNameservers"
						:key="`${record}-${index}`"
						class="z-body card__list-item"
					>
						{{ record }}
					</li>
				</ul>
			</InfoBanner>
			<a
				v-if="providerData.instructionsUrl"
				class="z-body z-link"
				:title="$t('siteSettings.domain.instructionsTitle')"
				:href="providerData.instructionsUrl"
				target="_blank"
			>
				{{ $t('siteSettings.domain.nameserversSettingsStep.howToFind') }}
			</a>
		</div>
	</ZyroCard>
</template>

<script>
import ZyroCard from '@/components/site-settings/components/ZyroCard.vue';
import InfoBanner from '@/components/site-settings/pages/domain/domain-connection/-partials/InfoBanner.vue';
import { useDomainConnection } from '@/components/site-settings/pages/domain/domain-connection/useDomainConnection';

export default {
	components: {
		ZyroCard,
		InfoBanner,
	},
	setup() {
		const {
			currentNameservers,
			providerData,
		} = useDomainConnection();

		return {
			currentNameservers,
			providerData,
		};
	},
};
</script>

<style lang="scss" scoped>
@import './ModalStepCard';

.card {
	&__banner {
		margin-bottom: 32px;
	}

	&__list {
		list-style: none;
	}

	&__list-item:not(:last-child) {
		margin-bottom: 8px;
	}
}
</style>
