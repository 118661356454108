<template>
	<ZyroCard class="card">
		<div class="card__content">
			<h2 class="z-h5 card__title">
				{{ $t('siteSettings.domain.providerInformationStep.title') }}
			</h2>
			<p class="z-body card__subtitle">
				{{ $t('siteSettings.domain.providerInformationStep.subtitle') }}
				<i18n
					v-if="!providerData.providerName"
					path="siteSettings.domain.providerInformationStep.findOutProvider"
				>
					<a
						class="z-link"
						href="https://www.whois.com/"
						target="_blank"
					>
						whois.com
					</a>.
				</i18n>
			</p>
			<div
				v-if="providerData.providerName"
				class="provider-card"
				data-qa="sitesettings-domain-card-provider"
			>
				<ZyroSvg
					:name="providerData.icon || 'globe-thin'"
					class="provider-card__logo"
				/>
				<div class="provider-card__info">
					<p class="z-body provider-card__info-text">
						<i18n
							path="siteSettings.domain.providerInformationStep.hostedBy"
							tag="span"
						>
							<span class="z-body--strong">
								{{ customDomain }}
							</span>
						</i18n>
						<br>
						<span class="z-body--strong">
							{{ providerData.providerName }}.
						</span>
					</p>
					<a
						v-if="providerData.url"
						class="z-link z-body-small"
						:href="getNonRelativeUrl(providerData.url)"
						target="_blank"
						:title="$t('siteSettings.domain.goToProviderSite')"
					>
						{{ $t('siteSettings.domain.providerInformationStep.signInTo') }}
						{{ providerData.providerName }}
					</a>
				</div>
			</div>
		</div>
	</ZyroCard>
</template>

<script>
import { mapState } from 'vuex';

import ZyroCard from '@/components/site-settings/components/ZyroCard.vue';
import { useDomainConnection } from '@/components/site-settings/pages/domain/domain-connection/useDomainConnection';
import { getNonRelativeUrl } from '@/utils/urlValidators';

export default {
	components: { ZyroCard },
	setup() {
		const { providerData } = useDomainConnection();

		return {
			getNonRelativeUrl,
			providerData,
		};
	},
	computed: mapState(['customDomain']),
};
</script>

<style lang="scss" scoped>
@import './ModalStepCard';

.provider-card {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	width: 100%;
	max-width: 507px;
	padding: 24px;
	background-color: $grey-100;

	&__logo {
		width: 70px;
		height: 70px;
		margin-right: 24px;
	}

	&__info-text {
		margin-bottom: 8px;
	}
}
</style>
