<template>
	<ZyroCard class="card">
		<form
			class="card__content"
			@submit.prevent="$emit('custom-domain-connection-submit')"
		>
			<h2 class="z-h5 card__title">
				{{ $t('siteSettings.domain.enterDomainCard.title') }}
			</h2>
			<p class="z-body card__subtitle">
				{{ $t('siteSettings.domain.enterDomainCard.subtitle') }}
			</p>
			<ZyroFieldText
				:value="domainInputValue"
				class="card__input"
				input-theme="secondary"
				:placeholder="$t('siteSettings.domain.enterDomainCard.placeholder')"
				:bold="false"
				:error="domainInputError"
				:focus-on-mount="true"
				data-qa="sitesettings-domain-inputfield-domainname"
				@input="$emit('domain-input', $event.target.value)"
			/>
		</form>
	</ZyroCard>
</template>

<script>
import ZyroCard from '@/components/site-settings/components/ZyroCard.vue';

export default {
	components: { ZyroCard },
	props: {
		domainInputValue: {
			type: String,
			default: '',
		},
		domainInputError: {
			type: String,
			default: '',
		},
	},
};
</script>

<style lang="scss" scoped>
@import './ModalStepCard';

.card {
	&__input {
		width: 564px;
		margin: 24px 0;

		@include mobile-view {
			width: 100%;
		}
	}
}
</style>
