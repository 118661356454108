<template>
	<ZyroModal
		:max-width="maxWidth"
		:max-height="maxHeight"
		:title="title"
		:is-rounded="isRounded"
		@close-modal="$emit('close')"
	>
		<slot />
		<template slot="footer">
			<div class="settings__modal-footer">
				<ZyroButton
					v-qa="`sitesettingsmodal-btn-${rightButtonText}`"
					class="button--right"
					:theme="rightButtonTheme"
					:color="rightButtonColor"
					:disabled="isRightButtonDisabled"
					@click="$emit('right-button-click')"
				>
					{{ rightButtonText }}
				</ZyroButton>
				<ZyroButton
					v-qa="`sitesettingsmodal-btn-${leftButtonText}`"
					class="button--left"
					:theme="leftButtonTheme"
					@click="$emit('left-button-click')"
				>
					{{ leftButtonText }}
				</ZyroButton>
			</div>
		</template>
	</ZyroModal>
</template>

<script>
import i18n from '@/i18n/setup';

export default {
	props: {
		rightButtonTheme: {
			type: String,
			default: 'primary',
		},
		isRounded: {
			type: Boolean,
			default: true,
		},
		rightButtonColor: {
			type: String,
			default: 'black',
		},
		rightButtonText: {
			type: String,
			default: i18n.t('common.save'),
		},
		isRightButtonDisabled: {
			type: Boolean,
			default: false,
		},
		leftButtonText: {
			type: String,
			default: i18n.t('common.cancel'),
		},
		leftButtonTheme: {
			type: String,
			default: 'outline',
		},
		title: {
			type: String,
			required: true,
		},
		maxWidth: {
			type: String,
			default: '606px',
		},
		maxHeight: {
			type: String,
			default: '256px',
		},
	},
};
</script>
<style lang="scss" scoped>
::v-deep .modal__content {
	@include mobile-view {
		max-height: 80px;
	}
}

.settings__modal-footer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;

	@include mobile-view {
		flex-direction: column;
	}

	.button--right {
		order: 2;

		@include mobile-view {
			order: 1;
			margin-bottom: 8px;
		}
	}

	.button--left {
		order: 1;

		@include mobile-view {
			order: 2;
		}
	}
}
</style>
