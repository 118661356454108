<template>
	<ZyroCard class="card">
		<div
			class="card__content"
			data-qa="sitesettings-domain-verification"
		>
			<ZyroSvg
				class="card__icon"
				name="globe-with-checkmark"
			/>
			<h2 class="z-h5 card__title">
				{{ $t('siteSettings.domain.hasDomain.giveItSomeTime') }}
			</h2>
			<p class="z-body card__subtitle">
				{{ $t('siteSettings.domain.hasDomain.verificationModalStepSubtitle') }}
			</p>
		</div>
	</ZyroCard>
</template>

<script>
import { mapState } from 'vuex';

import ZyroCard from '@/components/site-settings/components/ZyroCard.vue';

export default {
	components: { ZyroCard },
	computed: mapState(['customDomain']),
};
</script>

<style lang="scss" scoped>
@import './ModalStepCard';

.card {
	&__icon {
		margin-bottom: 48px;
	}

	&__subtitle {
		max-width: 420px;
	}
}
</style>
