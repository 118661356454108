<template>
	<ZyroModal
		width="963px"
		height="644px"
		class="domain-modal"
		is-title-centered
		:title="$t('siteSettings.domain.domainConnectionModal.title')"
		@close-modal="showStopConnectionModal = true"
	>
		<Stepper
			:steps="MODAL_STEPS"
			:current-step-index="currentStepIndex"
		/>
		<Transition
			name="slide-right"
			mode="out-in"
		>
			<Component
				:is="currentModalStepComponent"
				:domain-input-value="domainInputValue"
				:domain-input-error="inputDomainError"
				@custom-domain-connection-submit="handleContinueButtonClick"
				@domain-input="domainInputValue = $event"
			/>
		</Transition>
		<template slot="footer">
			<div class="domain-modal__footer">
				<ZyroButton
					v-if="isNotFirstModalStep"
					theme="plain"
					icon-left="arrow-left"
					icon-dimensions="16px"
					data-qa="sitesettings-domain-btn-go-back"
					@click="handleGoBackButtonClick"
				>
					{{ $t('common.goBack') }}
				</ZyroButton>
				<Transition name="fade">
					<ZyroButton
						v-if="!isLoading"
						theme="primary"
						class="domain-modal__footer-right-button"
						data-qa="sitesettings-domain-btn-continue"
						@click="handleContinueButtonClick"
					>
						{{ buttonText }}
					</ZyroButton>
					<ZyroButton
						v-else
						class="domain-modal__footer-right-button"
						theme="primary"
					>
						<ZyroLoader
							:style="{
								'--border-color': 'var(--light)',
								'--color': 'var(--accent-one-lighter)',
								'--size': '14px',
								'--weight': '2px',
								'--border': '2px'
							}"
						/>
					</ZyroButton>
				</Transition>
			</div>
		</template>
		<Portal to="app">
			<SiteSettingsModal
				v-if="showStopConnectionModal"
				:title="$t('siteSettings.domain.domainConnectionModal.stopConnectionModalTitle')"
				:left-button-text="$t('siteSettings.domain.domainConnectionModal.stopConnection')"
				:right-button-text="$t('siteSettings.domain.domainConnectionModal.continueConnection')"
				@close="showStopConnectionModal = false"
				@left-button-click="handleStopConnectionConfirmation"
				@right-button-click="showStopConnectionModal = false"
			>
				{{ $t('siteSettings.domain.domainConnectionModal.stopConnectionModalSubtitle') }}
			</SiteSettingsModal>
		</Portal>
	</ZyroModal>
</template>

<script>
import { mapState } from 'vuex';

import EventLogApi from '@/api/EventLogApi';
import SiteSettingsModal from '@/components/site-settings/components/SiteSettingsModal.vue';
import {
	MODAL_STEPS,
	ENTER_DOMAIN_CARD_STEP,
	PROVIDER_INFORMATION_STEP,
	VERIFICATION_MODAL_STEP,
	HAS_DOMAIN_STEP,
} from '@/components/site-settings/pages/domain/domain-connection/constants';
import EnterDomainCard from '@/components/site-settings/pages/domain/domain-connection/steps/EnterDomainCard.vue';
import NameserversInstructionsStep from '@/components/site-settings/pages/domain/domain-connection/steps/NameserversInstructionsStep.vue';
import NameserversSettingsStep from '@/components/site-settings/pages/domain/domain-connection/steps/NameserversSettingsStep.vue';
import ProviderInformationStep from '@/components/site-settings/pages/domain/domain-connection/steps/ProviderInformationStep.vue';
import VerificationModalStep from '@/components/site-settings/pages/domain/domain-connection/steps/VerificationModalStep.vue';
import { useDomainConnection } from '@/components/site-settings/pages/domain/domain-connection/useDomainConnection';
import Stepper from '@/components/ui/stepper/Stepper.vue';
import {
	mapActionsGui,
	CLOSE_MODAL,
} from '@/store/builder/gui';
import { isValidDomain } from '@/utils/urlValidators';

export default {
	components: {
		Stepper,
		EnterDomainCard,
		ProviderInformationStep,
		NameserversSettingsStep,
		NameserversInstructionsStep,
		VerificationModalStep,
		SiteSettingsModal,
	},
	setup() {
		const {
			isLoading,
			setCurrentStepComponent,
			setCurrentModalStepComponent,
			removeDomain,
			getProviderInformation,
			isDomainConnected,
			verifyConnection,
			connectCustomDomain,
			publishWithGeneratedDomain,
			hasFailed,
			currentModalStepComponent,
			domainConnectionError,
		} = useDomainConnection();

		return {
			isLoading,
			hasFailed,
			setCurrentStepComponent,
			setCurrentModalStepComponent,
			isDomainConnected,
			verifyConnection,
			removeDomain,
			getProviderInformation,
			connectCustomDomain,
			publishWithGeneratedDomain,
			currentModalStepComponent,
			domainConnectionError,
			MODAL_STEPS,
		};
	},
	data() {
		return {
			domainInputValue: '',
			inputDomainError: '',
			showStopConnectionModal: false,
		};
	},
	computed: {
		...mapState(['domain']),
		currentStepIndex: ({ currentModalStepComponent }) => MODAL_STEPS.findIndex(
			({ component }) => component === currentModalStepComponent,
		),
		buttonText: ({ currentStepIndex }) => MODAL_STEPS[currentStepIndex].buttonText,
		isNotFirstModalStep: ({ currentStepIndex }) => currentStepIndex !== 0,
	},
	methods: {
		...mapActionsGui({ closeModal: CLOSE_MODAL }),
		setNextStep() {
			this.setCurrentModalStepComponent(MODAL_STEPS[this.currentStepIndex + 1].component);
		},
		setPreviousStep() {
			this.setCurrentModalStepComponent(MODAL_STEPS[this.currentStepIndex - 1].component);
		},
		handleContinueButtonClick() {
			switch (this.currentModalStepComponent) {
			case ENTER_DOMAIN_CARD_STEP:
				if (!this.domainInputValue) {
					this.inputDomainError = this.$t('validate.emptyDomainName');

					return;
				}

				if (!isValidDomain(this.domainInputValue)) {
					this.inputDomainError = this.$t('validate.wrongDomainFormat');

					return;
				}

				this.inputDomainError = '';
				this.handleCustomDomainConnection();
				break;
			case VERIFICATION_MODAL_STEP:
				this.setCurrentStepComponent(HAS_DOMAIN_STEP);
				this.closeModal();
				break;
			default:
				this.setNextStep();
				break;
			}
		},
		async handleGoBackButtonClick() {
			if (this.currentModalStepComponent === PROVIDER_INFORMATION_STEP) {
				await this.removeDomain();

				if (this.hasFailed) {
					return;
				}
			}

			this.setPreviousStep();
		},
		async handleStopConnectionConfirmation() {
			if (this.isNotFirstModalStep) {
				await this.removeDomain();

				if (this.hasFailed) {
					return;
				}
			}

			this.showStopConnectionModal = false;
			this.closeModal();
		},
		async handleCustomDomainConnection() {
			this.inputDomainError = '';
			// If site has no domain, publish with a generated one
			await this.publishWithGeneratedDomain();
			if (this.hasFailed) {
				return;
			}

			// Try to connect the custom domain
			EventLogApi.logEvent({ eventName: 'site_settings.custom_domain.enter_domain_name' });
			await this.connectCustomDomain(this.domainInputValue);
			if (this.hasFailed) {
				this.inputDomainError = this.domainConnectionError;

				return;
			}

			// Verify connection.
			await this.verifyConnection(true);
			if (this.hasFailed) {
				return;
			}

			//  If it is verified, skip instructions.
			if (this.isDomainConnected) {
				this.setCurrentModalStepComponent(VERIFICATION_MODAL_STEP);

				return;
			}

			// Get domain registrar (set provider information) and go to next step
			EventLogApi.logEvent({ eventName: 'site_settings.custom_domain.see_instructions' });
			await this.getProviderInformation(this.domainInputValue);

			this.setCurrentModalStepComponent(PROVIDER_INFORMATION_STEP);
		},
	},
};
</script>

<style lang="scss" scoped>
.domain-modal {
	&__footer {
		display: flex;
		justify-content: space-between;
		width: 100%;
	}

	&__footer-right-button {
		margin-left: auto;
	}
}
</style>
