import {
	computed,
	ref,
	getCurrentInstance,
} from '@vue/composition-api';

import EventLogApi from '@/api/EventLogApi';
import {
	WHOIS_PROVIDER_INFORMATION,
	RDAP_PROVIDER_INFORMATION,
	RDAP_TYPE,
	TAKEN_DOMAIN_ERROR_MESSAGE,
} from '@/components/site-settings/pages/domain/domain-connection/constants';
import i18n from '@/i18n/setup';
import {
	useApi,
	PUBLISH_WITH_GENERATED_URL,
	IS_DOMAIN_CONNECTED,
	DOMAIN_WHOIS_DATA,
	CONNECT_CUSTOM_DOMAIN,
	DELETE_CUSTOM_DOMAIN,
} from '@/use/useApi';
import { useNotifications } from '@/use/useNotifications';

// Shared state
const currentStepComponent = ref('');
const currentModalStepComponent = ref('');
const hasVerificationTimedOut = ref(false);
const isDomainConnected = ref(false);
const currentNameservers = ref([]);
const providerData = ref({});

export const useDomainConnection = () => {
	const isBeingVerified = ref(false);
	const domainConnectionError = ref('');
	const {
		commit,
		state,
	} = getCurrentInstance()?.$store ?? {};
	const { notify } = useNotifications();

	const {
		isLoading,
		hasFailed,
		result,
		callApi,
		errorMessage,
	} = useApi();
	const setCurrentModalStepComponent = (stepValue) => {
		currentModalStepComponent.value = stepValue;
	};

	const setCurrentStepComponent = (stepValue) => {
		currentStepComponent.value = stepValue;
	};

	const setProviderData = (value) => {
		providerData.value = value;
	};

	const getProviderInformation = async (domainValue) => {
		await callApi(`${DOMAIN_WHOIS_DATA}${domainValue}`, { method: 'get' });
		if (hasFailed.value) {
			providerData.value = {};
			hasFailed.value = false;

			return;
		}

		const {
			registrar,
			registrarUrl,
			type,
			registrarId,
		} = result.value;

		const fallbackProviderData = {
			providerName: registrar,
			url: registrarUrl,
		};

		const providerInformation = type === RDAP_TYPE
			? RDAP_PROVIDER_INFORMATION[registrarId]
			: WHOIS_PROVIDER_INFORMATION[registrarUrl];

		providerData.value = providerInformation ?? fallbackProviderData;
	};

	const connectCustomDomain = async (domainValue) => {
		await callApi(CONNECT_CUSTOM_DOMAIN, {
			method: 'post',
			data: { customDomain: domainValue },
		}, {}, false);

		if (hasFailed.value) {
			// Hacky way to check for error type until we have better handling
			if (errorMessage.value === TAKEN_DOMAIN_ERROR_MESSAGE) {
				domainConnectionError.value = i18n.t('validate.domainNameTaken');
			} else {
				notify({
					message: 'Custom domain connection has failed.',
					origin: 'UseDomainConnection.js',
				});
			}

			return;
		}

		commit('setCustomDomain', domainValue);
	};

	const publishWithGeneratedDomain = async () => {
		const { domain } = state;

		if (domain) {
			hasFailed.value = false;

			return;
		}

		await callApi(PUBLISH_WITH_GENERATED_URL, {
			method: 'post',
			data: { generateDomain: true },
		}, {
			message: 'Couldn\'t publish the website with a generated domain.',
			origin: 'UseDomainConnection.js',
		});
		if (hasFailed.value) {
			return;
		}

		commit('setDomain', result.value.newDomain);
	};

	const verifyConnection = async (setTimestamp = false) => {
		isBeingVerified.value = true;
		const { customDomain } = state;

		if (!customDomain) {
			isBeingVerified.value = false;
			isDomainConnected.value = false;

			return;
		}

		await callApi(`${IS_DOMAIN_CONNECTED}${customDomain}`, {
			method: 'get',
			params: { setTimestamp },
		}, {
			message: 'Couldn\'t verify whether the domain is connected.',
			origin: 'UseDomainConnection.js',
		});
		isBeingVerified.value = false;
		if (hasFailed.value) {
			return;
		}

		isDomainConnected.value = !!result.value.ok;

		if (isDomainConnected.value) {
			EventLogApi.logEvent({ eventName: 'site_settings.custom_domain.domain_connected' });
		}

		/**
		 * If endpoint returns difference (seconds since last verification),
		 * check if 3h passed and set the value.
		 */
		if ('difference' in result.value) {
			const hasTimedOut = (result.value.difference / 3600) > 3;

			hasVerificationTimedOut.value = hasTimedOut;

			if (!isDomainConnected.value && hasVerificationTimedOut.value) {
				EventLogApi.logEvent({ eventName: 'site_settings.custom_domain.domain_connection_failed' });
			}
		}

		currentNameservers.value = result.value?.records?.ns ?? [];
	};

	const removeDomain = async () => {
		await callApi(DELETE_CUSTOM_DOMAIN, { method: 'delete' }, {
			message: 'Couldn\'t delete the custom domain.',
			origin: 'UseDomainConnection.js',
		});
		if (hasFailed.value) {
			return;
		}

		commit('setCustomDomain', null);
	};

	return {
		setCurrentStepComponent,
		setCurrentModalStepComponent,
		setProviderData,
		getProviderInformation,
		verifyConnection,
		connectCustomDomain,
		publishWithGeneratedDomain,
		removeDomain,
		isLoading: computed(() => isLoading.value),
		hasFailed: computed(() => hasFailed.value),
		isBeingVerified: computed(() => isBeingVerified.value),
		currentStepComponent: computed(() => currentStepComponent.value),
		currentModalStepComponent: computed(() => currentModalStepComponent.value),
		isDomainConnected: computed(() => isDomainConnected.value),
		hasVerificationTimedOut: computed(() => hasVerificationTimedOut.value),
		providerData: computed(() => providerData.value),
		currentNameservers: computed(() => currentNameservers.value),
		domainConnectionError: computed(() => domainConnectionError.value),
	};
};
