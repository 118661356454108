<template>
	<div class="progress">
		<div class="progress__steps">
			<Step
				v-for="({ content, isCurrent, isCompleted }, index) in stepsData"
				:key="`${content}-${index}`"
				class="progress__step"
				:content="content"
				:is-current="isCurrent"
				:is-completed="isCompleted"
			/>
		</div>
	</div>
</template>

<script>
import Step from './Step.vue';

export default {
	components: { Step },
	props: {
		steps: {
			type: Array,
			required: true,
		},
		currentStepIndex: {
			type: Number,
			required: true,
		},
	},
	computed: {
		stepsData: ({
			steps,
			currentStepIndex,
		}) => {
			const filteredSteps = steps.filter(({ isFinal }) => !isFinal);

			return filteredSteps.map((step, index) => ({
				...step,
				isCurrent: index === currentStepIndex,
				isCompleted: index < currentStepIndex,
			}));
		},
	},
};
</script>

<style lang="scss" scoped>
$offset: 64px;

.progress {
	text-align: center;
	border-top-left-radius: $border-radius-large;
	border-top-right-radius: $border-radius-large;

	&__steps {
		position: relative;
		display: flex;
		flex: 1 1 100%;
		justify-content: space-between;
		max-width: 508px;
		height: 2px;
		padding: 0 $offset;
		margin: 40px auto;
	}

	&__step {
		&:last-child {
			width: 0;
		}
	}
}
</style>
