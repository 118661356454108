import i18n from '@/i18n/setup';

export const TAKEN_DOMAIN_ERROR_MESSAGE = 'The domain is already taken';

/* COMPONENTS */
export const DOMAIN_CARD_STEP = 'DomainCardStep';
export const ENTER_DOMAIN_CARD_STEP = 'EnterDomainCard';
export const PROVIDER_INFORMATION_STEP = 'ProviderInformationStep';
export const NAMESERVERS_SETTINGS_STEP = 'NameserversSettingsStep';
export const NAMESERVERS_INSTRUCTIONS_STEP = 'NameserversInstructionsStep';
export const VERIFICATION_MODAL_STEP = 'VerificationModalStep';
export const INSTRUCTIONS_STEP = 'Instructions';
export const HAS_DOMAIN_STEP = 'HasDomain';

export const DNS_ZYRO_1 = 'dns1.zyro.com';
export const DNS_ZYRO_2 = 'dns2.zyro.com';

export const MODAL_STEPS = [
	{
		component: ENTER_DOMAIN_CARD_STEP,
		buttonText: i18n.t('common.continue'),
		content: '1',
	},
	{
		component: PROVIDER_INFORMATION_STEP,
		buttonText: i18n.t('common.continue'),
		content: '2',
	},
	{
		component: NAMESERVERS_SETTINGS_STEP,
		buttonText: i18n.t('siteSettings.domain.domainConnectionModal.foundButtonText'),
		content: '3',
	},
	{
		component: NAMESERVERS_INSTRUCTIONS_STEP,
		buttonText: i18n.t('siteSettings.domain.domainConnectionModal.replacedButtonText'),
		content: '4',
	},
	{
		component: VERIFICATION_MODAL_STEP,
		buttonText: i18n.t('common.done'),
		isFinal: true,
	},
];

// Modal names
export const REMOVE_DOMAIN_MODAL = 'remove_domain_modal';
export const NS_INSTRUCTIONS_URL = 'https://support.zyro.com/en/articles/4237563-how-do-i-connect-a-domain-to-my-website';
export const CORRECT_NAMESERVERS_LIST = [
	'dns1.zyro.com',
	'dns2.zyro.com',
	i18n.t('siteSettings.domain.hasDomain.leaveOtherFieldsEmpty'),
];

/*
 * Provider data
 * https://docs.google.com/spreadsheets/d/1CBZ3kHGh1XGY_duIkQEaeGVnI5gE3_Fx47H2JE7GRfE/edit#gid=0
 */
const PROVIDER_HOSTINGER = {
	providerName: 'Hostinger',
	icon: 'hostinger-logo',
	url: 'https://www.hostinger.com/cpanel-login',
	instructionsUrl: 'https://www.hostinger.com/tutorials/how-to-change-domain-nameservers',
};

const PROVIDER_NAMECHEAP = {
	providerName: 'Namecheap',
	icon: 'namecheap-logo',
	url: 'https://www.namecheap.com/myaccount/login/',
	instructionsUrl: 'https://www.namecheap.com/support/knowledgebase/article.aspx/767/10/how-to-change-dns-for-a-domain',
};

const PROVIDER_GODADDY = {
	providerName: 'GoDaddy',
	icon: 'godaddy-logo',
	url: 'https://dcc.godaddy.com/',
	instructionsUrl: 'https://uk.godaddy.com/help/change-nameservers-for-my-domains-664?',
};

const PROVIDER_CRAZYDOMAINS = {
	providerName: 'CrazyDomains',
	icon: 'crazydomains-logo',
	url: 'https://www.crazydomains.com.au/login/domain-name-login/',
	instructionsUrl: 'https://www.crazydomains.com.au/help/how-to-add-delete-change-your-name-servers/',
};

const PROVIDER_NAMECOM = {
	providerName: 'Name.com',
	icon: 'namecom-logo',
	url: 'https://www.name.com/account/login',
	instructionsUrl: 'https://www.name.com/support/articles/205934547-Changing-nameservers-for-DNS-management?keyword=nameserver',
};

const PROVIDER_IV = {
	providerName: 'Interneto vizija',
	icon: 'iv-logo',
	url: 'https://klientams.iv.lt/?command=signin',
	instructionsUrl: 'https://pagalba.iv.lt/Vard%C5%B3_serveri%C5%B3_(DNS)_keitimas',
};

const PROVIDER_OPENPROVIDER = {
	providerName: 'Openprovider',
	icon: 'openprovider-logo',
	url: 'https://cp.openprovider.eu/login.php',
	instructionsUrl: 'https://support.openprovider.eu/hc/en-us/articles/360026557334-How-to-change-nameservers-of-a-domain',
};

export const RDAP_TYPE = 'rdap';

export const WHOIS_PROVIDER_INFORMATION = {
	'https://www.hostinger.com': PROVIDER_HOSTINGER,
	'WWW.ENOM.COM': PROVIDER_NAMECHEAP,
	'http://www.godaddy.com': PROVIDER_GODADDY,
	'http://www.crazydomains.com': PROVIDER_CRAZYDOMAINS,
	'http://www.name.com': PROVIDER_NAMECOM,
	'https://www.iv.lt/': PROVIDER_IV,
	'http://www.registrar.eu': PROVIDER_OPENPROVIDER,
};

export const RDAP_PROVIDER_INFORMATION = {
	1636: PROVIDER_HOSTINGER,
	1068: PROVIDER_NAMECHEAP,
	146: PROVIDER_GODADDY,
	1291: PROVIDER_CRAZYDOMAINS,
	625: PROVIDER_NAMECOM,
	1647: PROVIDER_OPENPROVIDER,
};
