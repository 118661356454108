<template>
	<div class="step">
		<div class="step__icon-container">
			<div
				:class="[
					'step__icon',
					{
						'step__icon--active': isCurrent,
						'step__icon--filled': isCompleted,
					},
				]"
			>
				<Transition
					name="fade"
					mode="out-in"
				>
					<ZyroSvg
						v-if="isCompleted"
						name="check-mark"
					/>
					<span
						v-else
						class="step__icon-text"
					>
						{{ content }}
					</span>
				</Transition>
			</div>
		</div>
		<div
			class="step__status"
			:class="{ 'step__status--filled': isCompleted }"
		/>
	</div>
</template>

<script>
export default {
	props: {
		isCurrent: {
			type: Boolean,
			default: false,
		},
		isCompleted: {
			type: Boolean,
			default: false,
		},
		content: {
			type: String,
			default: '',
		},
	},
};
</script>

<style lang="scss" scoped>
$offset: 64px;
$transition: 0.3s ease-in-out;

.step {
	position: relative;
	width: 100%;

	&__icon-container {
		position: absolute;
		top: -16px;
		left: -16px;
		z-index: 2;
	}

	&__icon {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 32px;
		height: 32px;
		color: $grey-400;
		background-color: $light;
		border: 1.5px solid $grey-400;
		border-radius: $border-radius-round;
		transition: color $transition, border-color $transition, background-color $transition;

		&--active {
			color: $primary;
			border-color: $primary;
		}

		&--filled {
			background-color: $primary;
			border-color: $primary;
		}
	}

	&__icon-text {
		font-size: 13.125px;
		font-weight: bold;
		line-height: 19px;
	}

	&__status {
		position: relative;
		height: 2px;
		background: linear-gradient(to right, $primary 50%, $grey-400 50%) right bottom;
		background-size: 200% 100%;
		transition: background-position $transition;

		&--filled {
			background-position: left bottom;
		}
	}
}
</style>
