<template>
	<div
		class="zyro-input"
		:class="[
			{ [`zyro-input--${theme}`] : theme },
			{ 'z-body-small': theme === 'primary' },
			{ 'z-body': theme === 'secondary' },
		]"
	>
		<input
			class="zyro-input__input"
			v-bind="$attrs"
		>
		<ZyroTooltip
			class="zyro-input__copy-button"
			:use-portal="false"
			mode="dark"
			auto-close
			position="right"
			@trigger-click="copyToClipboard"
		>
			<template #trigger>
				<ZyroButton icon="duplicate" />
			</template>
			<span class="z-body-small">{{ $t('common.copied') }}</span>
		</ZyroTooltip>
	</div>
</template>

<script>
export default {
	props: {
		theme: {
			type: String,
			default: 'primary',
			validator: (value) => [
				'primary',
				'secondary',
			].includes(value),
		},
	},
	methods: {
		copyToClipboard() {
			navigator.clipboard.writeText(this.$attrs.value);
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@/components/global/zyro-input/ZyroInput';

.zyro-input {
	min-width: 308px;
	margin-bottom: 16px;

	&__input:not(.s) {
		width: 100%;
		padding-right: 40px;
		box-shadow: 0 0 6px rgba(0, 0, 0, 0.09);
	}

	&__copy-button {
		position: absolute;
		top: 8px;
		right: 6px;
	}
}
</style>
