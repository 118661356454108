<template>
	<ZyroCard class="card">
		<div class="card__content">
			<h2 class="z-h5 card__title">
				{{ $t("siteSettings.domain.nameserversInstructionsStep.title") }}
			</h2>
			<p class="z-body card__subtitle">
				{{ $t("siteSettings.domain.nameserversInstructionsStep.firstSubtitle") }}
				<br>
				{{ $t("siteSettings.domain.nameserversInstructionsStep.secondSubtitle") }}
			</p>
			<CopyField
				theme="secondary"
				readonly
				:value="DNS_ZYRO_1"
				data-qa="sitesettings-domain-field-nameserver-dns1"
			/>
			<CopyField
				theme="secondary"
				readonly
				:value="DNS_ZYRO_2"
				data-qa="sitesettings-domain-field-nameserver-dns2"
			/>
			<a
				class="z-link card__link"
				target="_blank"
				:title="$t('siteSettings.domain.instructionsTitle')"
				:href="NS_INSTRUCTIONS_URL"
			>
				{{ $t("siteSettings.domain.nameserversInstructionsStep.linkText") }}
			</a>
		</div>
	</ZyroCard>
</template>

<script>
import { mapState } from 'vuex';

import ZyroCard from '@/components/site-settings/components/ZyroCard.vue';
import CopyField from '@/components/site-settings/pages/domain/domain-connection/-partials/CopyField.vue';
import {
	NS_INSTRUCTIONS_URL,
	DNS_ZYRO_1,
	DNS_ZYRO_2,
} from '@/components/site-settings/pages/domain/domain-connection/constants';

export default {
	components: {
		ZyroCard,
		CopyField,
	},
	setup() {
		return {
			NS_INSTRUCTIONS_URL,
			DNS_ZYRO_1,
			DNS_ZYRO_2,
		};
	},
	computed: mapState(['customDomain']),
};
</script>

<style lang="scss" scoped>
@import './ModalStepCard';

.card {
	&__link {
		margin-top: 16px;
	}
}
</style>
