<template>
	<ZyroCard class="banner">
		<ZyroSvg
			:name="iconName"
			class="banner__icon"
		/>
		<div class="banner__content">
			<slot name="title">
				<p class="z-body-small z-body-small--strong z-body-small--spaced">
					{{ title }}
				</p>
			</slot>
			<slot />
			<div
				v-if="$slots.button"
				class="banner__button"
			>
				<slot name="button" />
			</div>
		</div>
	</ZyroCard>
</template>

<script>
import ZyroCard from '@/components/site-settings/components/ZyroCard.vue';

export default {
	components: { ZyroCard },
	props: {
		title: {
			type: String,
			default: '',
		},
		iconName: {
			type: String,
			default: 'clock',
		},
	},
};
</script>

<style lang="scss" scoped>
.banner {
	// A rare case of https://github.com/vuejs/vue-loader/issues/808, needs increased specificity
	&:not(.s) {
		position: relative;
		padding: 24px 24px 24px 72px;
		background: var(--background-color, var(--accent-three-lighter));
		border: 1px solid var(--background-color, var(--accent-three-lighter));
		border-radius: 5px;
	}

	@include mobile-view {
		padding: 16px;
	}

	&__content {
		margin-right: 16px;
	}

	&__button {
		margin-top: 24px;
	}

	&__icon {
		position: absolute;
		top: 26px;
		left: 24px;
	}
}
</style>
